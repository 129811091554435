import { render, staticRenderFns } from "./PlayerFavorite.vue?vue&type=template&id=c7405af4&scoped=true"
import script from "./PlayerFavorite.vue?vue&type=script&lang=js"
export * from "./PlayerFavorite.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/player/playerfavorite.css?vue&type=style&index=0&id=c7405af4&prod&scoped=true&lang=css&external"
import style1 from "@/assets/css/modal/space/playstop.css?vue&type=style&index=1&id=c7405af4&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7405af4",
  null
  
)

export default component.exports